import { createRouter, createWebHistory } from "vue-router";
import { ErrorPage } from "@paper-co/web-toolkit";

import googleClassroomAddonRoutes from "@/modules/GoogleClassroomAddon/router/index.ts";

let router;
const routes = [
	{
		path: "",
		redirect: "/teacher/assignments",
		component: () => import("@/pages/index.vue"),
		children: [
			{
				path: "/teacher/assignments",
				component: () => import("@/pages/assignments.vue"),
			},
			...googleClassroomAddonRoutes,
		],
	},
	{
		path: "/:pathMatch(.*)",
		component: ErrorPage,
	},
];

export const initializeRouter = () => {
	router = createRouter({
		history: createWebHistory(),
		routes,
	});

	return router;
};

export default router;

