import { ref } from "vue";
import { defineStore } from "pinia";
import type { AccessTokenUserObject } from "@paper-co/web-toolkit";

export const useAuthStore = defineStore("auth", () => {
	const currentUser = ref<AccessTokenUserObject | null>(null);

	const setCurrentUser = (user: AccessTokenUserObject | null) => {
		currentUser.value = user;
	};
	return {
		currentUser,
		setCurrentUser,
	};
});

