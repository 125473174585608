import { createApp, defineAsyncComponent } from "vue";
import type { Component as VueComponent, App as VueApp } from "vue";
import { createPinia } from "pinia";
import jsCookie from "js-cookie";
import jwtDecode from "jwt-decode";
import { createI18n } from "vue-i18n";
import {
	VueLaunchDarkly,
	initializeLaunchDarkly,
	identifyLaunchDarklyUser,
	initializeDatadog,
	initializeSentry,
	initializePendo,
	initializeZendesk,
	identifyZendeskUser,
	initializeGoogleAnalytics,
	identifyGoogleAnalyticsUser,
	googleAnalyticsPlugin,
	captureException,
} from "@paper-co/web-toolkit";
import type { AccessTokenUserObject } from "@paper-co/web-toolkit";
import { initializeAssignments } from "@paper-co/common-assignments-web-js-package";

import { logout, refreshAccessToken } from "@/services/auth.ts";
import { getRoles, getUser } from "@/services/gradeslam-api/index.ts";
import { initializeRouter } from "@/router/index.ts";
import { useAuthStore } from "@/store/auth.ts";
import App from "@/App.vue";

async function refreshTokenOrLogout() {
	try {
		return await refreshAccessToken();
	} catch (error) {
		captureException(error);
		await logout();
	}
}

let app: VueApp | undefined;
function createVueApp(component: VueComponent) {
	app = createApp(component);
	const pinia = createPinia();
	const router = initializeRouter();
	const i18n = createI18n({
		legacy: false,
		locale: jsCookie.get("preferredLanguage") || "en",
		fallbackLocale: "en",
	});

	initializeSentry({ app }).catch(captureException);
	app.use(VueLaunchDarkly);
	app.use(googleAnalyticsPlugin, router);
	app.use(router);
	app.use(pinia);
	app.use(i18n);
}

const loginComponent = defineAsyncComponent(() => import("@/pages/devLogin.vue"));

export async function initApp(props?: { element?: string }) {
	try {
		const refreshToken = jsCookie.get("refresh_token");
		let accessToken = jsCookie.get("access_token");
		if (process.env.VITE_APP_ENV === "development" && (!refreshToken || !accessToken)) {
			createVueApp(loginComponent);
			return;
		}
		createVueApp(App);
		if (!refreshToken) {
			await logout();
		}
		if (!accessToken) {
			accessToken = await refreshTokenOrLogout();
		}

		const { user = null } = jwtDecode<{ user: AccessTokenUserObject | null }>(accessToken || "");
		const authStore = useAuthStore();
		authStore.$onAction(({ name, args }) => {
			if (name === "setCurrentUser") {
				const currentUser = args[0];
				if (currentUser) {
					identifyLaunchDarklyUser(currentUser).catch(captureException);
					identifyZendeskUser(currentUser).catch(captureException);
					identifyGoogleAnalyticsUser(currentUser).catch(captureException);
				}
			}
		});
		authStore.setCurrentUser(user);

		initializeLaunchDarkly({ user }).catch(captureException);
		initializeDatadog().catch(captureException);
		initializeGoogleAnalytics({ user }).catch(captureException);
		if (user) {
			initializePendo({ user, getUser, getRoles })
				.then(() => initializeZendesk())
				.catch(captureException);
		}
		initializeAssignments({
			assignmentsApiUrl: (import.meta as any).env.VITE_ASSIGNMENTS_API_URL,
			gradeslamApiUrl: (import.meta as any).env.VITE_GRADESLAM_API_URL,
			googleClientId: (import.meta as any).env.VITE_GOOGLE_CLIENT_ID,
		});
	} catch (error) {
		captureException(error);
	} finally {
		app!.mount(props?.element || "#app");
	}
}

export const destroyApp = () => {
	if (!app) {
		console.warn("The vue instance does not exist");
		return;
	}
	app.unmount();
	app = undefined;
};
