type MicroFrontendEventMap = {
	[key: string]: string;
}

// Events to be published
export const MICRO_FRONTENDS_EVENTS: MicroFrontendEventMap = {
	ROUTE_UPDATE: "microAppRouteUpdate",
};

// Events subcsriptions
export const MICRO_FRONTENDS_SUBSCRIPTION_EVENTS: MicroFrontendEventMap = {
	ROUTE_CHANGE: "hostRouteChange",
};

export type MicroFrontendEventName =
	keyof typeof MICRO_FRONTENDS_EVENTS | keyof typeof MICRO_FRONTENDS_SUBSCRIPTION_EVENTS;

export const publishEvent = (eventName: MicroFrontendEventName, data?: any) => {
	const event = new CustomEvent(
		eventName as string,
		{
			detail: data,
		},
	);
	window.dispatchEvent(event);
};

export const redirectTo = (relativePath: string) => {
	if (import.meta.env.VITE_IS_STANDALONE === "true") {
		const url = `${import.meta.env.VITE_SPA_WEB_URL}${relativePath.startsWith("/") ? relativePath : `/${relativePath}`}`;
		window.location.assign(url);
		return;
	} else {
		publishEvent(MICRO_FRONTENDS_EVENTS.ROUTE_UPDATE, { to: relativePath });
	}
};
