/* eslint-disable import/no-cycle */// TODO: replace with auth module

import jsCookie from "js-cookie";
import jwtDecode from "jwt-decode";
import type { AccessTokenUserObject } from "@paper-co/web-toolkit";

import { refreshToken as apiRefreshToken, login as apiLogin } from "@/services/gradeslam-auth/index.ts";
import { REFRESH_TOKEN_DURATION_IN_DAYS, TOKEN_DURATION_IN_MS } from "@/utils/constant.ts";
import { useAuthStore } from "@/store/auth.ts";

export function authUrl(url: string) {
	return `${process.env.VITE_GRADESLAM_AUTH_URL}/${url}`;
}

export function logout() {
	if (process.env.VITE_APP_ENV === "development") {
		jsCookie.remove("access_token");
		jsCookie.remove("refresh_token");
		console.warn("Forced logout.");
	} else {
		window.location.href = authUrl("logout");
	}
}

export async function refreshAccessToken() {
	const storedRefreshToken = jsCookie.get("refresh_token");

	if (!storedRefreshToken) {
		throw new Error("no refresh token");
	}

	const { accessToken, refreshToken } = await apiRefreshToken(storedRefreshToken);

	rememberTokens(accessToken, refreshToken);

	return accessToken;
}

export async function login(email: string, password: string) {
	const { accessToken, refreshToken } = await apiLogin(email, password);
	rememberTokens(accessToken, refreshToken);

	return accessToken;
}

function rememberTokens(accessToken: string, refreshToken: string) {
	const authStore = useAuthStore();
	const { user } = jwtDecode<{ user: AccessTokenUserObject }>(accessToken);
	authStore.setCurrentUser(user);
	jsCookie.set("access_token", accessToken, {
		expires: new Date(new Date().getTime() + TOKEN_DURATION_IN_MS),
		secure: true,
	});

	jsCookie.set("refresh_token", refreshToken, {
		expires: REFRESH_TOKEN_DURATION_IN_DAYS,
		secure: true,
	});
}
