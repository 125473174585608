import type { AxiosRequestConfig } from "axios";

import type { GetUserSectionsParams } from "@/utils/types";
import http from "@/services/http.ts";

function apiRequest(config: AxiosRequestConfig) {
	return http.request({
		baseURL: process.env.VITE_GRADESLAM_API_URL,
		...config,
	});
}

export async function getUser(id: number | string, include?: string) {
	return await apiRequest({
		url: `/users/${id}`,
		params: {
			include,
		},
	});
}

export async function getRoles() {
	return await apiRequest({ url: "/roles" });
}

export async function getUserSections(userId: number, params?: GetUserSectionsParams) {
	return apiRequest({
		url: `/users/${userId}/sections`,
		params,
	});
}
