/* eslint-disable import/no-cycle */// TODO: replace with auth module

import axios from "axios";
import type { InternalAxiosRequestConfig } from "axios";
import jsCookie from "js-cookie";

import { authUrl, logout, refreshAccessToken } from "@/services/auth.ts";

let isAlreadyFetchingAccessToken = false;
let subscribers: ((accessToken: string) => void)[] = [];

const http = axios.create({
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});

const authorizeRequests = async(config: InternalAxiosRequestConfig<any>) => {
	const accessToken = jsCookie.get("access_token") || null;

	if (accessToken) {
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${accessToken}`,
		} as InternalAxiosRequestConfig["headers"];
	}

	return config;
};

http.interceptors.request.use(authorizeRequests);

http.interceptors.response.use(function(response) {
	return response;
}, async function(error) {
	const { config, response } = error;
	const originalRequest = config;

	if (response?.status === 429) {
		window.location.href = authUrl("error.html");
	} else if (response?.status === 401 && originalRequest.url !== "/login") {
		// add to the stack the http call
		const retryOriginalRequest = new Promise((resolve) => {
			subscribers.push((access_token) => {
				originalRequest.headers.Authorization = "Bearer " + access_token;
				resolve(http(originalRequest));
			});
		});
		// if a call to refresh token is already happenning
		if (!isAlreadyFetchingAccessToken) {
			isAlreadyFetchingAccessToken = true;
			try {
				const at = await refreshAccessToken();
				isAlreadyFetchingAccessToken = false;
				subscribers = subscribers.filter((callback) => callback(at));
			} catch (error) {
				logout();
			}
		}

		return retryOriginalRequest;
	}

	return Promise.reject(error);
});

export default http;
