<template>
	<OrigamiIconSprite data-test-id="origami-icon-sprite" />
	<RouterView />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { OrigamiIconSprite } from "@origami/vue3";

import useMicroFrontendEvents from "@/composables/useMicroFrontendEvents.ts";

const { subscribeToEvents, unsubscribeToEvents } = useMicroFrontendEvents();
onMounted(() => {
	subscribeToEvents();
});
onUnmounted(() => {
	unsubscribeToEvents();
});
</script>

<style lang="postcss">
@import "@origami/vue3/style.css";

@import "@/assets/css/index.css";
</style>
