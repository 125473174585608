import { MICRO_FRONTENDS_SUBSCRIPTION_EVENTS } from "@/utils/microfrontend.ts";
import type { MicroFrontendEventName } from "@/utils/microfrontend.ts";

export default function useMicroFrontendEvents() {
	const eventsHandlers: { [eventName: MicroFrontendEventName]: EventListenerOrEventListenerObject } = {
		/**
		 * Insert subscription events handlers here
		 * for example:
		 * [MICRO_FRONTENDS_SUBSCRIPTION_EVENTS.GRADE_LEVEL_UPDATE]: (event) => updateGradeLevel(event.detail),
		 * 
		 */
	};

	const subscribeToEvents = () => {
		Object.values(MICRO_FRONTENDS_SUBSCRIPTION_EVENTS).forEach((eventName) => {
			window.addEventListener(
				eventName,
				eventsHandlers[eventName],
			);
		});
	};

	const unsubscribeToEvents = () => {
		Object.values(MICRO_FRONTENDS_SUBSCRIPTION_EVENTS).forEach((eventName) => {
			window.removeEventListener(
				eventName,
				eventsHandlers[eventName],
			);
		});
	};

	return {
		subscribeToEvents,
		unsubscribeToEvents,
	};
}
