/* eslint-disable import/no-cycle */// TODO: replace with auth module

import type { AxiosRequestConfig, AxiosResponse } from "axios";

import http from "@/services/http.ts";

function apiRequest(config: AxiosRequestConfig) {
	return http.request({
		baseURL: process.env.VITE_GRADESLAM_AUTH_URL,
		...config,
	});
}

function parseAccessTokenResponse(responseData: AxiosResponse["data"]) {
	return {
		accessToken: responseData.access_token,
		refreshToken: responseData.refresh_token,
	};
}

export async function refreshToken(refreshToken: string) {
	const { data } = await apiRequest({
		method: "post",
		url: "/refresh-token",
		data: { refresh_token: refreshToken },
	});

	return parseAccessTokenResponse(data);
}

export async function login(email: string, password: string) {
	const { data } = await apiRequest({
		method: "post",
		url: "/login",
		data: { email, password },
	});

	return parseAccessTokenResponse(data);
}
