import { getFlag, ErrorPage } from "@paper-co/web-toolkit";

const routes = [
	{
		path: "/teacher/assignments/google-classroom/create",
		component: async() => await getFlag("grow-5050-google-classroom-addon") ? import("../pages/index.vue") : ErrorPage,
	},
];

export default routes;

