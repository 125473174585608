import { ASSIGNMENT_TYPE_NAMES } from "@paper-co/common-assignments-web-js-package";
/**
	* JWT token expiration added to current time
	* 8 minutes represented in MS to play with Date.getTime()
*/
export const TOKEN_DURATION_IN_MS = 8 * 60 * 1000;
export const REFRESH_TOKEN_DURATION_IN_DAYS = 180;

export const ASSIGNMENT_TYPES_FORMATTED_NAMES: {
	[key: string]: string;
} = {
	[ASSIGNMENT_TYPE_NAMES.REVIEW_CENTER]: "Writing Review",
	[ASSIGNMENT_TYPE_NAMES.PULSE_QUIZ]: "Pulse Question",
	[ASSIGNMENT_TYPE_NAMES.LIVE_HELP]: "Tutoring Session",
};
